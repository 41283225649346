
/**
 * @returns Date in bribane formated as DD Weekday Month
 */
const dateNowBris = (): string => {
    const dateTime = new Date();

    const formattedDate = dateTime.toLocaleDateString("en-AU", {
        timeZone: "Australia/Brisbane",
        weekday: "short",
        day: "numeric",
        month: "short"
    });

    return formattedDate;
}

/**
 * @returns Time in Brisbane formatted in "short" time style
 */
const timeNowBris = (): string => {
    const dateTime = new Date();

    const formattedTime = dateTime.toLocaleTimeString("en-AU", {
        timeZone: "Australia/Brisbane",
        timeStyle: "short"
    });

    return formattedTime;
}

/**
 * @param date Date string
 * @returns formated date DD-MM-YYY
 */
const getformattedDate = (date: string): string => {

    const formateDate = new Date(date);

    return `${formateDate.getDate()}-${formateDate.getMonth()}-${formateDate.getFullYear()} `
}

export { dateNowBris, timeNowBris, getformattedDate };